import { lazy } from 'react';
import ReactDOM from 'react-dom/client';

const blocks: { [name: string]: any } = {
  root: lazy(() => import('./organisms/root/Root')),
  'main-menu-dropdown': lazy(() => import('@organisms/header/MainMenuDropdown')),
  'signup-form': lazy(() => import('@organisms/signup-form/SignupForm')),
  'personal-signup': lazy(() => import('@organisms/signup-form/PersonalSignup')),
  'billing-and-delivery': lazy(() => import('@organisms/signup-form/BillingAndDelivery')),
  'preferences-and-membership': lazy(() => import('@organisms/signup-form/PreferencesAndMembership')),
  'search-auto-complete': lazy(() => import('@molecules/search-auto-complete/SearchAutoComplete')),
  'modal-popup': lazy(() => import('@organisms/modal-popup/ModalPopup')),
  checkout: lazy(() => import('./organisms/checkout/Checkout')),
  'my-account': lazy(() => import('@organisms/my-account-form/MyAccountForm')),
  'forgot-password': lazy(() => import('@organisms/forgot-password/ForgotPassword')),
  'reset-password': lazy(() => import('@organisms/forgot-password/ResetPassword')),
};

const loadComponent = (scriptSection: HTMLScriptElement) => {
  const blockType = scriptSection.getAttribute('data-rct');
  const data = scriptSection.textContent ? scriptSection.textContent : '{}';

  if (!blockType || !data) {
    return;
  }

  const Component = blocks[blockType];
  if (Component) {
    scriptSection.textContent = null;
    const section = document.createElement(scriptSection.getAttribute('data-tag') ?? 'section');
    section.className = scriptSection.getAttribute('data-class') ?? '';
    scriptSection.replaceWith(section);

    const props = JSON.parse(data);
    ReactDOM.createRoot(section).render(<Component {...props} />);
  } else {
    return <></>;
  }
};

const loadComponents = () => {
  // rct stands for 'react component type'
  const scriptSections = document.querySelectorAll('script[data-rct]');

  [].forEach.call(scriptSections, loadComponent);
};

loadComponents();
window.addEventListener('load', () => loadComponents());

window.addEventListener('cartChanged', (event: any) => {
 loadComponents();
});
